<template>
  <section class="active-rides">
      <div class="tbl-header">
        <table cellpadding="0" cellspacing="0" border="0">
        <thead>
            <tr>
            <th>Date</th>
            <th>Route</th>
            <th>Price</th>
            <th>Seets</th>
            <th>Book</th>
            </tr>
        </thead>
        </table>
    </div>
    <div class="tbl-content">
        <table cellpadding="0" cellspacing="0" border="0">
        <tbody>
            <tr v-for="ride in rides" :key="ride">
                <td>{{row.id}}</td>
                <td>{{row.date}}</td>
                <td>{{row.route}}</td>
                <td>{{row.passengers}}</td>
                <td>{{row.status}}</td>
            </tr>
            <tr>
                <td>AAC</td>
                <td>AUSTRALIAN COMPANY </td>
                <td>$1.38</td>
                <td>+2.01</td>
                <td>-0.36%</td>
            </tr>
            <tr>
                <td>AAD</td>
                <td>AUSENCO</td>
                <td>$2.38</td>
                <td>-0.01</td>
                <td>-1.36%</td>
            </tr>
            <tr>
                <td>AAX</td>
                <td>ADELAIDE</td>
                <td>$3.22</td>
                <td>+0.01</td>
                <td>+1.36%</td>
            </tr>
            
        </tbody>
        </table>
    </div>
  </section>
</template>

<script>

    export default {
        name: 'ActiveRides',
        data() {
			return {
				rides: '',
			};
		},
        methods: {
            getRides: function(){
                axios.get('action.php')
                .then(function (response) {
                    console.log(response.data);
                    app.rides = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        created:function(){
            this.fetchAllData();
        },
        mounted() {

            $(window).on("load resize ", function() {
                var scrollWidth = $('.tbl-content').width() - $('.tbl-content table').width();
                $('.tbl-header').css({'padding-right':scrollWidth});
            }).resize();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .active-rides{
        background: #000;

        h1{
        font-size: 30px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 300;
        text-align: center;
        margin-bottom: 15px;
        }
        table{
        width:100%;
        table-layout: fixed;
        }
        .tbl-header{
        background-color: rgba(255,255,255,0.3);
        }
        .tbl-content{
        height:300px;
        overflow-x:auto;
        margin-top: 0px;
        border: 1px solid rgba(255,255,255,0.3);
        }
        th{
        padding: 20px 15px;
        text-align: left;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        }
        td{
        padding: 15px;
        text-align: left;
        vertical-align:middle;
        font-weight: 300;
        font-size: 12px;
        color: #fff;
        border-bottom: solid 1px rgba(255,255,255,0.1);
        }


        /* demo styles */

        @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
        body{
        background: -webkit-linear-gradient(left, #25c481, #25b7c4);
        background: linear-gradient(to right, #25c481, #25b7c4);
        font-family: 'Roboto', sans-serif;
        }
        section{
        margin: 50px;
        }


        /* follow me template */
        .made-with-love {
        margin-top: 40px;
        padding: 10px;
        clear: left;
        text-align: center;
        font-size: 10px;
        font-family: arial;
        color: #fff;
        }
        .made-with-love i {
        font-style: normal;
        color: #F50057;
        font-size: 14px;
        position: relative;
        top: 2px;
        }
        .made-with-love a {
        color: #fff;
        text-decoration: none;
        }
        .made-with-love a:hover {
        text-decoration: underline;
        }


        /* for custom scrollbar for webkit browser*/

        ::-webkit-scrollbar {
            width: 6px;
        } 
        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        } 
        ::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        }
    }
</style>