import { render, staticRenderFns } from "./ButtonsComponent.vue?vue&type=template&id=33f75216&scoped=true&"
var script = {}
import style0 from "./ButtonsComponent.vue?vue&type=style&index=0&id=33f75216&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f75216",
  null
  
)

export default component.exports